<template>
  <div>
    <!-- Breadcrumb start -->
    <section class="bradcrumb_sec">
      <div class="container-fluid custome_container">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="bread_crumb_box mb_22">
              <h2 class="heading_1 font_bold mb_23">Seating</h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link
                      class="navbar-brand m-0 p-0"
                      :to="{ name: 'user_home' }"
                    >
                      Home
                    </router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'setup_guidline' }"
                      >Setup Guidelines</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Seating
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Breadcrumb end -->
    <!-- pagination Starts -->
    <section class="pagination_sec">
      <div class="pagination-nav">
        <div class="container-fluid custome_container">
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row justify-content-between">
                <div class="col-md-12">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-between">
                      <li class="page-item">
                        <a
                          class="d-none page-link border-0 text_black"
                          aria-label="Previous"
                        >
                          <span aria-hidden="true">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="29.631"
                              viewBox="0 0 43.575 29.631"
                            >
                              <g
                                id="Left_arrow"
                                data-name="Left arrow"
                                transform="translate(2.75 3.967)"
                              >
                                <path
                                  id="Path_30"
                                  data-name="Path 30"
                                  d="M1755.8,353.578l-10.914,10.889,10.937,10.965"
                                  transform="translate(-1744.886 -353.656)"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="5.5"
                                />
                                <path
                                  id="Path_31"
                                  data-name="Path 31"
                                  d="M1755.8,353.578l-10.914,10.889,10.937,10.965"
                                  transform="translate(-1728.886 -353.656)"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="5.5"
                                />
                              </g>
                            </svg>
                            Previous
                          </span>
                        </a>
                      </li>
                      <li class="page-item">
                        <router-link
                          :to="{ name: 'desk' }"
                          class="page-link border-0 text_black"
                          aria-label="Next"
                        >
                          <span aria-hidden="true" class="font_bold">
                            <span class="text_primary"
                              >Up Next<span class="mobile_hide">: </span></span
                            ><span class="mobile_hide"> Desk</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="33.575"
                              height="29.631"
                              viewBox="0 0 33.575 29.631"
                              style="margin-left: 5px"
                            >
                              <g
                                id="Right_arrow"
                                data-name="Right arrow"
                                transform="translate(-12884.354 20078.389)"
                              >
                                <path
                                  id="Path_30"
                                  data-name="Path 30"
                                  d="M1744.908,353.578l10.914,10.889-10.937,10.965"
                                  transform="translate(11159.357 -20428.078)"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="5.5"
                                />
                                <path
                                  id="Path_31"
                                  data-name="Path 31"
                                  d="M1744.908,353.578l10.914,10.889-10.937,10.965"
                                  transform="translate(11143.357 -20428.078)"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="5.5"
                                />
                              </g>
                            </svg>
                          </span>
                        </router-link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- pagination Ends -->
    <!-- image text start -->
    <section class="image_text_sec bg_grey pt_50 pb_50 mt_30">
      <div class="container-fluid custome_container">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="row">
              <div class="col-md-6">
                <div class="image_left_side">
                  <img src="../assets/images/seating_img.png" alt="img" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="image_text_right_side">
                  <h2 class="mb_22">Is your seating working for you?</h2>
                  <p class="mb_30">
                    It’s safe to say that you sit a lot during your work day.
                    Your chair doesn’t have to be fancy, but it does need to
                    offer decent back support and allow you to type and mouse
                    with your shoulders and elbows at the correct levels.
                  </p>
                  <p class="mb_20">
                    Here are some basic setup and sitting tips to help you sit
                    with healthy posture.
                  </p>
                  <div class="">
                    <a
                      href="#"
                      class="btn btn_outline"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="play_icon"
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                      >
                        <circle
                          id="bg"
                          cx="15.5"
                          cy="15.5"
                          r="15.5"
                          fill="#ff9500"
                        ></circle>
                        <g
                          id="Triangle"
                          transform="translate(21.478 9.205) rotate(90)"
                          fill="#fff"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                        >
                          <path
                            d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                            stroke="none"
                          ></path>
                          <path
                            d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                            stroke="none"
                            fill="#fff"
                          ></path>
                        </g>
                      </svg>
                      Watch Video Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center down_to_section newarrow">
        <a
          v-scroll-to="{
            el: '#accordian_secc',
            duration: 500,
            easing: 'linear',
            offset: -350,
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50.132"
            height="35.219"
            viewBox="0 0 57.132 61.219"
          >
            <g
              id="Down_arrow"
              data-name="Down arrow"
              transform="translate(-20020.894 -12891.354) rotate(90)"
            >
              <path
                id="Path_30"
                data-name="Path 30"
                d="M1744.937,353.578l24.648,24.591-24.7,24.763"
                transform="translate(11180.239 -20427.715)"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="5.5"
              />
              <path
                id="Path_31"
                data-name="Path 31"
                d="M1744.937,353.578l24.648,24.591-24.7,24.763"
                transform="translate(11150.357 -20427.715)"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="5.5"
              />
            </g>
          </svg>
        </a>
      </div>
    </section>
    <!-- Set Yourself up for success Ends -->
    <!-- Accordion  start -->
    <section id="accordian_secc" class="accordion_sec my_80">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-xl-8">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <img src="../assets/images/dual_arrow.png" alt="img" /> How
                    high should my chair be?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      <span class="font_bold"> Ideal seat height</span> will
                      allow you to sit so that your knees are level with or
                      slightly lower than your hips when your feet are flat on
                      the floor or footrest.
                    </p>
                    <p>
                      <span class="font_bold">
                        If your chair is adjustable,</span
                      >
                      you can change its height as needed. If your chair is not
                      adjustable, you can raise your seat height with a seat
                      cushion, folded towels or anything else that is soft, flat
                      and stable.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <img src="../assets/images/user_chair.png" alt="img" /> What
                    about the depth of my seat?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      <span class="font_bold"
                        >To be comfortable in your seat,</span
                      >
                      maintain 2-4 inches (5-10cm) between the backs of your
                      knees and the seat pan, when you are seated at the back of
                      your chair.
                    </p>
                    <p>
                      <span class="font_bold">If your seat is deep </span>
                      (i.e., couch, recliner chair, etc.) you can try putting a
                      throw pillow behind you which will bring you more forward
                      in the seat and in essence, “shorten” the depth of your
                      seat. Remember, to change things up, you can also sit
                      toward the front of your seat.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <img src="../assets/images/user_table.png" alt="img" /> How
                    do I keep my back comfortable?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      <span class="font_bold"
                        >If you choose to sit against the back of your chair, </span
                      >make sure you have adequate support for the lumbar area
                      of your spine (your lower back). If your chair does not
                      have adequate lumbar support, a rolled up towel or small
                      pillow might just do the trick!
                    </p>
                    <p>
                      <span class="font_bold"
                        >Maintain a neutral pelvis and natural body
                        alignment</span
                      >
                      to minimize back strain. And if you choose to sit in a
                      slightly reclined position, then make sure that your lower
                      back is supported with no “void” or space between you and
                      the back of your chair.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Accordion  end -->
    <!-- more WFH tips start  -->
    <section class="more_lab_tips bg_grey pt_42">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-xl-11 px_20">
            <div class="row">
              <div class="col-md-12">
                <h4 class="heading_4 font_bold mb-0">More setup guidelines</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6 col-xl-4">
                <router-link
                  :to="{ name: 'seating' }"
                  class="more_lab_tip_link"
                >
                  <div class="d-flex setup-guide">
                    <div class="flex-shrink-0 media-left">
                      <img
                        src="../assets/images/setup_guidline/seating.png"
                        alt="img"
                        class="img-fluid"
                      />
                    </div>
                    <div class="flex-grow-1 ml_20 media-right">
                      <h6 class="font_size_22 text_black mb_10">Seating</h6>
                      <p>
                        Ergo has your back (literally) no matter where you sit.
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-sm-12 col-md-6 col-xl-4">
                <router-link :to="{ name: 'desk' }" class="more_lab_tip_link">
                  <div class="d-flex setup-guide">
                    <div class="flex-shrink-0 media-left">
                      <img
                        src="../assets/images/setup_guidline/setup_guidline4.png"
                        alt="img"
                        class="img-fluid"
                      />
                    </div>
                    <div class="flex-grow-1 ml_20 media-right">
                      <h6 class="font_size_22 text_black mb_10">Desk</h6>
                      <p>Learn to set yourself up well at any desk you use.</p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-sm-12 col-md-6 col-xl-4">
                <router-link
                  :to="{ name: 'desktop_items' }"
                  class="more_lab_tip_link"
                >
                  <div class="d-flex setup-guide">
                    <div class="flex-shrink-0 media-left">
                      <img
                        src="../assets/images/setup_guidline/setup_guidline3.png"
                        alt="img"
                        class="img-fluid"
                      />
                    </div>
                    <div class="flex-grow-1 ml_20 media-right">
                      <h6 class="font_size_22 text_black mb_10">
                        Desktop Items
                      </h6>
                      <p>
                        How and what you place on your desk impacts your work
                        style.
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-sm-12 col-md-6 col-xl-4">
                <router-link
                  :to="{ name: 'screens' }"
                  class="more_lab_tip_link"
                >
                  <div class="d-flex setup-guide">
                    <div class="flex-shrink-0 media-left">
                      <img
                        src="../assets/images/setup_guidline/setup_guidline2.png"
                        alt="img"
                        class="img-fluid"
                      />
                    </div>
                    <div class="flex-grow-1 ml_20 media-right">
                      <h6 class="font_size_22 text_black mb_10">Screens</h6>
                      <p>
                        Wanna have great posture and be productive? Learn how.
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-sm-12 col-md-6 col-xl-4">
                <router-link
                  :to="{ name: 'accessories' }"
                  class="more_lab_tip_link"
                >
                  <div class="d-flex setup-guide">
                    <div class="flex-shrink-0 media-left">
                      <img
                        src="../assets/images/setup_guidline/setup_guidline1.png"
                        alt="img"
                        class="img-fluid"
                      />
                    </div>
                    <div class="flex-grow-1 ml_20 media-right">
                      <h6 class="font_size_22 text_black mb_10">Accessories</h6>
                      <p>
                        It’s all in the details. Learn how accessories can help.
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col-sm-12 col-md-6 col-xl-4">
                <router-link
                  :to="{ name: 'lighting' }"
                  class="more_lab_tip_link"
                >
                  <div class="d-flex setup-guide">
                    <div class="flex-shrink-0 media-left">
                      <img
                        src="../assets/images/setup_guidline/setup_guidline6.png"
                        alt="img"
                        class="img-fluid"
                      />
                    </div>
                    <div class="flex-grow-1 ml_20 media-right">
                      <h6 class="font_size_22 text_black mb_10">Lighting</h6>
                      <p>Learn how to use light to improve your output.</p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- more WFH tips end  -->

    <!-- Video Modal start Here -->
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-end">
              <a
                href="#"
                class="modal_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideModal"
                ><img src="../assets/images/close_modal.png"
              /></a>
            </div>
            <div class="video_elements">
              <iframe
                id="closed5"
                src="https://player.vimeo.com/video/140750648?h=e9628fe2de&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="Sitting Pretty"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Video Modal End Here -->
  </div>
</template>

<script>
export default {
  name: "Seating",
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
  },
  methods: {
    hideModal() {
      document.getElementById("closed5").src += "";
    },
  },
};
</script>